import React, { useEffect } from "react";
import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { ThumbUpOffAlt, ErrorOutline, Schedule } from "@mui/icons-material";
import LabeledData from "./LabeledData";
import LabeledDataList from "./LabeledDataList";
import { toDisplayDate } from "utils/dates";
import EnhancedProfileButton from "components/Button/Button";
import GoogleMap from "./GoogleMap";
import { useCompanyContext } from "contexts/CompanyContext";
import { IAccountInfo } from "types";
import { useApi } from "hooks/useApi";
import { TabPages } from "pages/constants";
import FilingDialog from "./Tabs/Filings/CustomerReferenceDialog";
import { Userpilot } from "userpilot";
import {
  dark,
  mid,
  secondary,
  secondary_mid,
  middark,
  legalAmber,
  primary_mid,
} from "../variables";
import Status from "components/Status/Status";

type Props = {
  sx: SxProps;
  setSelectedTab: (tab: TabPages) => void;
};

const RegistrationInformation: React.FC<Props> = ({
  sx,
  setSelectedTab,
}): JSX.Element => {
  const {
    companyProfile: cp,
    liteLoading,
    liteUnavailable,
    jurisdiction,
    loadEnhancedProfile,
    setLoadEnhancedProfile,
    enhancedProfileButtonShown,
    enhancedProfileButtonDisabled,
    enhancedLoading,
    enhancedLoaded,
    enhancedProfileButtonText,
    enhancedUnavailable,
    documentLink,
    uboVerifyEnabled,
    enhancedProfileCost,
    enhancedOrderRef,
    setEnhancedOrderRef,
  } = useCompanyContext();

  const [epOrderRef, setEpOrderRef] = React.useState<string>("");
  const [epDialogOpen, setEpDialogOpen] = React.useState<boolean>(false);
  const [disableEpDialog, setDisableEpDialog] = React.useState<boolean>(false);
  const isUSCompanyCode =
    jurisdiction.startsWith("US") && cp?.code?.includes("_");

  const isNullString = (str: string) => (str === "null" ? true : false);
  const companyActivity = cp?.activities
    ?.filter(
      (a) =>
        (a.code && !isNullString(a.code)) ||
        (a.description && !isNullString(a.description)),
    )
    .map((a) => {
      if (
        a.code &&
        !isNullString(a.code) &&
        a.description &&
        !isNullString(a.description)
      )
        return `${a.code} - ${a.description}`;
      else if (a.code && !isNullString(a.code)) return `${a.code}`;
      else return `${a.description}`;
    });

  const { data } = useApi<IAccountInfo>("/accountInfo");
  const userHasUboVerifyEnabled = data?.isUboVerifyEnabled || false;

  const registrationDate = cp?.dates?.registered
    ? (toDisplayDate(cp?.dates?.registered) as string)
    : undefined;

  const foundationDate = cp?.dates?.foundationDate
    ? (toDisplayDate(cp?.dates?.foundationDate) as string)
    : undefined;

  const lastAnnualAccountDate = cp?.lastAnnualAccountDate
    ? (toDisplayDate(cp?.lastAnnualAccountDate) as string)
    : undefined;

  const isInformationSupplied =
    cp.addresses[0]?.addressInOneLine ||
    (companyActivity && companyActivity.length > 0) ||
    cp?.legalFormDeclaration ||
    cp?.legalForm ||
    cp?.legalFormSubType ||
    cp?.registrationNumber ||
    cp?.registrationAuthority ||
    registrationDate ||
    foundationDate ||
    cp.addresses[0]?.country ||
    cp?.fiscalCode ||
    lastAnnualAccountDate ||
    cp?.telephoneNumber ||
    cp?.vatNumber ||
    cp?.stateOfIncorporation ||
    cp?.agentName ||
    cp?.agentAddress ||
    cp?.aliases ||
    cp?.email ||
    cp?.faxNumber ||
    cp?.companyNameInEnglish ||
    cp?.websiteURL;

  useEffect(() => {
    if (enhancedOrderRef) {
      setLoadEnhancedProfile(true);
    }
  }, [enhancedOrderRef, setLoadEnhancedProfile]);

  const getEnhancedProfile = () => {
    if (epOrderRef) {
      setEnhancedOrderRef(epOrderRef);
    } else {
      setLoadEnhancedProfile(true);
    }
    if (uboVerifyEnabled && userHasUboVerifyEnabled) {
      setSelectedTab(TabPages.UBO);
    }
  };

  const handleDisableEpDialog = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDisableEpDialog(event.target.checked);
  };

  const setDisableEpDialogSetting = () => {
    localStorage.setItem(
      "disableFilingDialog",
      JSON.stringify(disableEpDialog),
    );
  };

  const getDisableEpDialogSetting = () => {
    const json = localStorage.getItem("disableFilingDialog");
    if (json) {
      const disableEpDialog = JSON.parse(json);
      return disableEpDialog;
    }
    return false;
  };

  const normalizedLegalStatusColour = React.useMemo(() => {
    switch (cp.normalizedLegalStatus) {
      case "ACTIVE":
        return secondary_mid;
      case "INACTIVE":
      case "OTHER":
        return middark;
      case "DISTRESSED":
        return legalAmber;
      default:
        return secondary_mid;
    }
  }, [cp.normalizedLegalStatus]);

  return (
    <Box sx={{ gridColumn: "1 / span 1", ...sx }}>
      <Typography
        variant="h4"
        fontSize="2.375rem"
        fontFamily="Sohne"
        color="primary.dark"
        mb={1}
      >
        {cp.name}
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Typography
          variant="caption"
          color="grey.500"
          fontWeight={500}
          fontSize="0.875rem"
        >
          {!liteLoading && !liteUnavailable && !isUSCompanyCode
            ? cp.code
            : undefined}
        </Typography>
        {cp.normalizedLegalStatus && (
          <Status
            color={normalizedLegalStatusColour}
            status={cp.normalizedLegalStatus ?? ""}
            statusDetail={cp.legalStatus ?? ""}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 4,
          gap: "16px",
        }}
      >
        {!enhancedLoading &&
          !enhancedLoaded &&
          enhancedProfileButtonShown &&
          !enhancedUnavailable && (
            <Box
              sx={{ display: "flex", flexDirection: "column", marginBottom: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "18px",
                }}
              >
                <Typography sx={{ fontWeight: 500 }} color="primary.dark">
                  Get realtime access to the full company profile
                </Typography>
              </Box>
              {/* Container for button and text */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <EnhancedProfileButton
                  disabled={enhancedProfileButtonDisabled}
                  onClick={() => {
                    Userpilot.track("Enhanced Profile Clicked");

                    if (!getDisableEpDialogSetting()) {
                      setEpDialogOpen(true);
                    } else {
                      getEnhancedProfile();
                    }
                  }}
                  sx={{
                    width: "185px",
                    marginRight: "12px", // Space between button and icon/text
                    backgroundColor: secondary_mid,
                    color: "#223243",
                    "&:hover": {
                      backgroundColor: secondary,
                    },
                    fontWeight: 600,
                  }}
                >
                  {enhancedProfileButtonText}
                </EnhancedProfileButton>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Schedule
                    fontSize="small"
                    sx={{ color: mid, marginRight: "4px" }}
                  />
                  <Typography
                    variant="caption"
                    color={dark}
                    sx={{ fontSize: "0.875rem", fontWeight: 400 }}
                  >
                    Live Registry Data
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        {!enhancedLoading && !enhancedLoaded && enhancedUnavailable && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <ErrorOutline color="error" />
            <Typography color="error" sx={{ fontSize: "0.75em" }}>
              Registry is unavailable at this time, please try again later
            </Typography>
          </Box>
        )}
        {enhancedLoading && !enhancedLoaded && enhancedProfileButtonShown && (
          <>
            <Typography sx={{ fontWeight: 500 }} color="primary.dark">
              Get realtime access to the full company profile
            </Typography>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 1 }}>
              <CircularProgress
                size="1em"
                color="success"
                variant="indeterminate"
              />
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600 }}
                color={primary_mid}
              >
                Connecting to registry...
              </Typography>
            </Box>
          </>
        )}
        {!enhancedLoading && enhancedLoaded && enhancedProfileButtonShown && (
          <>
            <Typography sx={{ fontWeight: 500 }} color="primary.dark">
              Get realtime access to the full company profile
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                fontWeight: 600,
              }}
            >
              <ThumbUpOffAlt color="success" />
              <Typography color="success.main" sx={{ fontSize: "0.75em" }}>
                Enhanced profile generated successfully!
              </Typography>
            </Box>
            {documentLink && (
              <EnhancedProfileButton
                sx={{
                  marginBottom: 2,
                }}
                onClick={() => {
                  const win = window.open(documentLink, "_blank");
                  win && win.focus();
                }}
              >
                Download Enhanced Profile PDF
              </EnhancedProfileButton>
            )}
          </>
        )}
        <Typography
          sx={{ marginTop: "8px", marginBottom: "5px" }}
          color="primary.dark"
        >
          Registration Information
        </Typography>
        {!isInformationSupplied && (
          <LabeledData label="" data={"Not supplied by registry"} />
        )}
        <LabeledData
          label="Registered Address"
          data={cp.addresses[0]?.addressInOneLine}
        />
        <LabeledDataList label="Company Activity" dataList={companyActivity} />

        <LabeledData
          label="Legal Form"
          data={cp?.legalFormDeclaration || cp?.legalForm}
        />
        <LabeledData label="Legal Form Sub Type" data={cp?.legalFormSubType} />
        <LabeledData
          label="Registration Number"
          data={cp?.registrationNumber}
        />
        <LabeledData
          label="Registration Authority"
          data={cp?.registrationAuthority}
        />
        <LabeledData label="Registration Date" data={registrationDate} />
        <LabeledData label="Foundation Date" data={foundationDate} />
        <LabeledData label="Country" data={cp.addresses[0]?.country} />
        <LabeledData label="Fiscal Code" data={cp?.fiscalCode} />
        <LabeledData
          label="Last Annual Account Date"
          data={lastAnnualAccountDate}
        />
        <LabeledData label="Telephone Number" data={cp?.telephoneNumber} />
        <LabeledData label="VAT Number" data={cp?.vatNumber} />
        <LabeledData
          label="State of Incorporation"
          data={cp?.stateOfIncorporation}
        />
        <LabeledData label="Agent" data={cp?.agentName} />
        <LabeledData label="Agent Address" data={cp?.agentAddress} />
        {cp?.aliases?.some(Boolean) && (
          <LabeledDataList
            label="Aliases"
            dataList={cp?.aliases.filter(Boolean)}
          />
        )}
        <LabeledData label="Email Address" data={cp?.email} />
        <LabeledData label="Fax Number" data={cp?.faxNumber} />
        <LabeledData
          label="Company Name in English"
          data={cp?.companyNameInEnglish}
        />
        <LabeledData label="Website URL" data={cp?.websiteURL} />
        {enhancedLoaded && cp.capital && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              marginTop: 2,
            }}
          >
            <Typography color="primary.dark">Capital</Typography>
            {cp.capital.map(({ type, ammount, currency }) => (
              <LabeledData
                label={type}
                data={currency ? `${currency} ${ammount}` : ammount}
              />
            ))}
          </Box>
        )}
        {(loadEnhancedProfile || enhancedLoaded) &&
          cp?.addresses[0]?.addressInOneLine && (
            <GoogleMap address={cp.addresses[0]?.addressInOneLine} />
          )}
      </Box>
      <FilingDialog
        dialogOpen={epDialogOpen}
        setDialogOpen={setEpDialogOpen}
        creditCost={enhancedProfileCost}
        customerRef={epOrderRef}
        setCustomerRef={setEpOrderRef}
        disableDialog={disableEpDialog}
        handleDisableDialog={handleDisableEpDialog}
        doAction={getEnhancedProfile}
        persistDisableDialogSetting={setDisableEpDialogSetting}
        orderType="enhanced profile"
      ></FilingDialog>
    </Box>
  );
};

export default RegistrationInformation;
