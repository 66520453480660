import React, { useContext, useEffect, useState } from "react";
import qs from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import QueryString from "query-string";
import { KyckrError, useApi } from "hooks/useApi";
import {
  IEnhancedCompanyProfileResponse,
  ILiteCompanyProfile,
  IProductFeaturesResponse,
} from "pages/Company/types";
import { ISearchResult } from "pages/Search/types";
import { CompanyProfile, Filing } from "./types";
import { useCreditContext } from "../CreditContext";
import useFilings from "./useFilings";
import { disabledLiteProfileIsoCodes } from "../../disabledLiteProfileIsoCodes";
import { transform } from "utils/jurisdiction";
import { isNullOrWhitespace } from "utils/string";
interface Props {
  isManual?: boolean;
  children?: React.ReactNode;
}

type CompanyContextValue = {
  companyProfile: CompanyProfile;
  setCompanyProfile: (value: CompanyProfile) => void;
  loadEnhancedProfile: boolean;
  setLoadEnhancedProfile: (value: boolean) => void;
  documentLink: string | undefined;
  liteLoading: boolean;
  liteError: KyckrError | null;
  liteUnavailable: boolean;
  enhancedLoading: boolean;
  enhancedError: KyckrError | null;
  enhancedLoaded: boolean;
  enhancedProfileButtonShown: boolean;
  enhancedProfileButtonDisabled: boolean;
  enhancedUnavailable: boolean;
  uboVerifyEnabled: boolean;
  enhancedProfileCost: number | undefined;
  enhancedProfileButtonText: string;
  filings: Filing[];
  filingsLoading: boolean;
  setLoadFilingsManually: (value: boolean) => void;
  jurisdiction: string;
  companyCode: string;
  enhancedOrderRef: string;
  setEnhancedOrderRef: (value: string) => void;
};

export const CompanyContext = React.createContext<
  CompanyContextValue | undefined
>(undefined);

export function useCompanyContext(): CompanyContextValue {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error("useCompanyContext must be used within a CompanyContext");
  }

  return context;
}

export const CompanyContextProvider: React.FC<Props> = ({
  isManual,
  children,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const regAuth = query.get("registrationAuthorityCode");
  const { credits, refetchCredits } = useCreditContext();
  const { companyCode, jurisdiction, companyName, registrationAuthorityCode } =
    QueryString.parse(location.search);

  const searchResult = location.state as ISearchResult;

  let initialCompanyProfile: ILiteCompanyProfile = {
    activities: [],
    legalForm: "",
    legalFormDeclaration: "",
    stateOfIncorporation: "",
    agentName: "",
    agentAddress: "",
    dates: {
      registered: undefined,
      foundationDate: undefined,
    },
    responseCode: "",
    responseDetails: "",
    code: companyCode,
    addresses: searchResult ? searchResult.addresses : [],
    name: decodeURIComponent(searchResult ? searchResult.name : companyName),
  };

  const liteProfileParams = qs.stringify(
    {
      companyName: initialCompanyProfile.name,
      ...(!isNullOrWhitespace(registrationAuthorityCode) && {
        registrationAuthorityCode: registrationAuthorityCode,
      }),
      ...(searchResult &&
        !isNullOrWhitespace(searchResult.registrationAuthorityCode) && {
          registrationAuthorityCode: searchResult.registrationAuthorityCode,
        }),
    },
    { skipNull: true },
  );

  const liteProfileCallEnabled =
    !disabledLiteProfileIsoCodes.includes(jurisdiction) && !isManual;

  const liteResponse = useApi<ILiteCompanyProfile>(
    `/lite/profile/${transform(jurisdiction)}/${encodeURIComponent(
      companyCode,
    )}?${liteProfileParams}`,
    {
      enabled: liteProfileCallEnabled,
      onSuccess: (data: ILiteCompanyProfile) => {
        if (
          data.responseCode === "105" ||
          data.responseDetails === "Company not found"
        ) {
          setLiteUnavailable(true);
        }
      },
    },
  );

  const { isLoading: liteLoading, data: liteProfile } = liteResponse;

  if (!searchResult) {
    if (liteProfile) {
      initialCompanyProfile = {
        ...initialCompanyProfile,
        name: liteProfile.name,
        companyNameInEnglish: liteProfile.companyNameInEnglish,
        addresses: liteProfile.addresses || [],
        legalStatus: liteProfile.legalStatus,
        registrationAuthority: liteProfile.registrationAuthority,
        registrationAuthorityCode: liteProfile.registrationAuthorityCode,
      };
    }
  } else {
    initialCompanyProfile = {
      ...initialCompanyProfile,
      name:
        (searchResult.isoAlpha2Code?.length ?? 0) > 0
          ? undefined
          : searchResult.name,
      companyNameInEnglish: searchResult.companyNameInEnglish,
      addresses: searchResult.addresses || [],
      legalStatus: searchResult.legalStatus,
      registrationAuthority: searchResult.registrationAuthority,
      registrationAuthorityCode: searchResult.registrationAuthorityCode,
    };
  }

  const [companyProfile, setCompanyProfile] = useState<CompanyProfile>(
    initialCompanyProfile,
  );

  const [enhancedOrderRef, setEnhancedOrderRef] = useState<string>("");
  if (regAuth) {
    companyProfile.registrationAuthorityCode = regAuth;
  }
  const [loadEnhancedProfile, setLoadEnhancedProfile] =
    useState<boolean>(false);
  const [enhancedLoaded, setEnhancedLoaded] = useState<boolean>(false);
  const [documentLink, setDocumentLink] = useState<string | undefined>();

  const [enhancedUnavailable, setEnhancedUnavailable] =
    useState<boolean>(false);

  const [liteUnavailable, setLiteUnavailable] = useState<boolean>(false);

  let { error: liteError } = liteResponse;

  const enhancedProfileParams = qs.stringify({
    companyName: companyProfile.name,
    registrationAuthorityCode: companyProfile.registrationAuthorityCode,
    orderRef: enhancedOrderRef,
  });
  const {
    isLoading: enhancedLoading,
    error: enhancedError,
    data: enhancedProfileResponse,
  } = useApi<IEnhancedCompanyProfileResponse>(
    `/EnhancedCompanyProfile/${jurisdiction}/${encodeURIComponent(
      companyCode,
    )}?${enhancedProfileParams}`,
    {
      enabled: loadEnhancedProfile,
      staleTime: 1,
      onSuccess: (data: IEnhancedCompanyProfileResponse) => {
        if (
          data.responseCode === "105" ||
          data.responseDetails === "Company not found" ||
          data.responseCode === "500" ||
          data.responseCode === "104"
        ) {
          setEnhancedUnavailable(true);
        }

        refetchCredits();
      },
    },
  );

  useEffect(() => {
    if (liteProfile && liteProfile.name && !enhancedProfileResponse) {
      liteProfile.registrationAuthorityCode =
        initialCompanyProfile.registrationAuthorityCode;
      setCompanyProfile(liteProfile);

      if (companyName) {
        const {
          ["companyName"]: cpName,
          ["jurisdiction"]: _,
          ...rest
        } = QueryString.parse(location.search);

        const queryParams = QueryString.stringify(rest);

        navigate(
          {
            pathname: location.pathname,
            search: `?jurisdiction=${jurisdiction}&${queryParams}`,
          },
          {
            replace: true,
            state: {
              ...searchResult,
              name: cpName,
            } as ISearchResult,
          },
        );
      }
    }

    if (liteProfile && liteProfile.code === "K-EBP-0012") {
      // TODO:: This external shouldn't be set from here
      // eslint-disable-next-line react-hooks/exhaustive-deps
      liteError = {
        name: "",
        message: "",
        code: liteProfile.code,
      };
    }
  }, [liteProfile]);

  useEffect(() => {
    if (
      enhancedProfileResponse &&
      enhancedProfileResponse?.companyProfile &&
      !enhancedLoaded
    ) {
      enhancedProfileResponse.companyProfile.registrationAuthorityCode =
        initialCompanyProfile.registrationAuthorityCode;
      const { activity, registrationDate, foundationDate, ...rest } =
        enhancedProfileResponse.companyProfile;
      const documentLink = enhancedProfileResponse?.retrievalLocation;
      setEnhancedLoaded(true);
      setLoadEnhancedProfile(false);
      // filter non truthy values
      // TODO:: This whole function needs a nuke. It doesn't even work,
      // there are nested objects that will always seem truthy even if they're filled with falsy values
      const filteredEnhancedProfile = Object.keys(rest).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, key) => {
          const _acc = acc;
          if ((rest as CompanyProfile)[key as keyof CompanyProfile]) {
            _acc[key] = (rest as CompanyProfile)[key as keyof CompanyProfile];
          }

          return _acc;
        },
        {},
      );

      setCompanyProfile({
        ...companyProfile,
        ...filteredEnhancedProfile,
        activities:
          activity && activity.length > 0
            ? activity
            : companyProfile.activities,
        dates: {
          registered: registrationDate ?? companyProfile.dates?.registered,
          foundationDate: foundationDate ?? companyProfile.dates?.registered,
        },
      });
      setDocumentLink(documentLink);
    }
    // TODO:: not all of this needs to be in the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhancedProfileResponse]);

  const productAPIRequest = useApi<IProductFeaturesResponse>(
    `/productfeatures/${jurisdiction}`,
    { enabled: !isManual },
  );
  const productFeatureSupport = productAPIRequest.data?.productFeature?.[0];
  const productCost = productFeatureSupport?.companyProfileCreditValue || 0;
  const enhancedProfileButtonShown = Boolean(
    productFeatureSupport?.hasCompanyProfile,
  );
  const enhancedProfileButtonDisabled =
    credits && credits.remainingCredits != undefined
      ? credits.remainingCredits < productCost
      : false;
  const uboVerifyEnabled = productFeatureSupport?.hasUboVerify || false;
  const enhancedProfileCost = productFeatureSupport?.companyProfileCreditValue;
  const enhancedProfileButtonText = enhancedProfileButtonDisabled
    ? "Not enough credits"
    : `View on screen for ${enhancedProfileCost || ""} Credit${
        enhancedProfileCost && enhancedProfileCost > 1 ? "s" : ""
      }`;

  const [loadFilingsManually, setLoadFilingsManually] =
    useState<boolean>(false);

  const { isLoading: filingsLoading, filings } = useFilings(
    companyProfile,
    companyCode,
    jurisdiction,
    loadFilingsManually,
  );

  return (
    <CompanyContext.Provider
      value={{
        companyProfile,
        setCompanyProfile,
        loadEnhancedProfile,
        setLoadEnhancedProfile,
        documentLink,
        liteLoading,
        liteError,
        liteUnavailable,
        enhancedLoading,
        enhancedError,
        enhancedLoaded,
        enhancedProfileButtonShown,
        enhancedProfileButtonDisabled,
        enhancedUnavailable,
        uboVerifyEnabled,
        enhancedProfileCost,
        enhancedProfileButtonText,
        filings,
        filingsLoading,
        setLoadFilingsManually,
        jurisdiction,
        companyCode,
        enhancedOrderRef,
        setEnhancedOrderRef,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
